<template>
    <b-card>
        <b-form-group label="ประเภทโปรโมชั่นที่ต้องการเปิด" >
            <b-form-checkbox-group
              v-model="form"
              :options="options"
              name="allowPromotionTypeList"
              stacked
            ></b-form-checkbox-group>
        </b-form-group>
    </b-card>
</template>

<script>
import Vue from 'vue';

export default Vue.extend ({
  name: 'PromotionSetting',
  props: {
    agentData: {
      type: Object,
      default: () => ({
        allowPromotionTypeList: [],
      }),
    },
  },
  data() {
    return {
      form: [],
      payload: {},
      options: [
        { text: 'เครดิตฟรี', value: '0' },
        { text: 'ฝากครั้งแรก (สมาชิกใหม่)', value: '1' },
        { text: 'ฝากครั้งแรกของวัน', value: '2' },
        { text: 'ทุกยอดฝาก', value: '3' },
        { text: 'นาทีทอง', value: '4' },
        { text: 'กงล้อ', value: '5' },
        { text: 'เปิดไพ่', value: '6' },
        { text: 'ฝากต่อเนื่อง', value: '7' },
        { text: 'Welcome Back', value: '8' },
        { text: 'แนะนำเพื่อน', value: '9' },
        { text: 'โบนัสพิเศษ', value: '10' },
        { text: 'Cashback', value: '11' },
      ],
    }
  },
  watch: {
    form(val) {
      this.$emit('update', {
        allowPromotionTypeList: [...val]
      })
    }
  },
  created() {
    this.form = [...this.agentData.allowPromotionTypeList]
  }
})
</script>